import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import StoreContext from 'src/context/StoreContext'
import LoadingEllipsis from 'src/components/LoadingEllipsis'
import { HiddenOnMobile } from 'src/utils/styles/global-styles'
import { FormattedCurrency } from 'src/utils/FormattedCurrency'
import {
  CAL_BUY_LINK,
  CAL_MAX_BUY_LINK,
  CAL_MAX_BLACK_BUY_LINK,
  CAL_MAX_WHITE_BUY_LINK,
  CAL_MAX_ALUMINUM_BUY_LINK,
  CAL_MAX_CHARCOAL_BUY_LINK,
  IS_FOREIGN_COUNTRY,
  FRAME_2_BLACK_BUY_LINK,
  FRAME_2_WHITE_BUY_LINK,
  FRAME_2_LAGOON_BUY_LINK,
  FRAME_2_SAGE_BUY_LINK,
  FRAME_2_WALNUT_BUY_LINK,
  FRAME_2_BIRCH_BUY_LINK,
  FRAME_2_CHARCOAL_BUY_LINK,
  FRAME_2_ALUMINUM_BUY_LINK,
  FRAME_2_COVER_WHITE_BUY_LINK,
  FRAME_2_COVER_BLACK_BUY_LINK,
  RETURN_GUARANTEE_PRODUCT_HANDLE,
} from '../../../utils/constants'
import {
  TableRow,
  DesktopOnlyTD,
  TD,
  TitleLink,
  Remove,
  ProductTD,
  ProductInfo,
  LineItemTotalCtn,
  HiddenOnDesktop,
  LeftQuantityButton,
  RightQuantityButton,
  Price,
  FrameWithPlusExplainer,
  SubTotal,
} from './styles'
import { shouldRemoveReturnGuarantee } from '../../../utils/addReturnGuarantee'

const LineItem = ({
  item,
  updatingPrices,
  setUpdatingPrices,
  strikethrough,
  subtotalQuantity,
  setHasFrame2,
  setHasSnapFrame,
  isFreeProduct = false,
}) => {
  const [shown, setShown] = useState(true)
  const { updateLineItem, removeLineItem } = useContext(StoreContext)
  const {
    id,
    quantity,
    merchandise: {
      image: { url },
      priceV2: { amount },
      product: { title, handle },
      title: variantTitle,
    },
  } = item?.node ?? {}

  const { t } = useTranslation('common')

  useEffect(() => {
    if (!IS_FOREIGN_COUNTRY) {
      const isFrame2 = title.includes('Skylight Frame 2')
      const isSnapFrame = title.includes('Snap Frame')
      setHasFrame2((prev) => (isFrame2 ? true : prev))
      setHasSnapFrame((prev) => (isSnapFrame ? true : prev))
      return () => {
        if (isFrame2) setHasFrame2(false)
        if (isSnapFrame) setHasSnapFrame(false)
      }
    }
  }, [title, setHasFrame2, setHasSnapFrame])

  const updatePricesWrapper = async (cb) => {
    setUpdatingPrices(true)
    await cb()
    setUpdatingPrices(false)
  }

  const handleRemoveLineItem = async (removeId) => {
    await updatePricesWrapper(async () => {
      setShown(false)
      // eslint-disable-next-line no-unused-vars
      const updatedCart = await removeLineItem(removeId)
      // checkForAvailableDiscount(updatedCart)
    })
  }

  const decrementLineItem = async () => {
    await updatePricesWrapper(async () => {
      const newQuantity = quantity - 1
      const updatedCart = await updateLineItem(id, newQuantity)
      if (shouldRemoveReturnGuarantee(updatedCart?.lines?.edges)) {
        handleRemoveLineItem(
          updatedCart?.lines?.edges.find(
            (item) => item.node.merchandise.product.handle === RETURN_GUARANTEE_PRODUCT_HANDLE
          )?.node?.id
        )
      }
      // checkForAvailableDiscount(updatedCart)
    })
  }

  const incrementLineItem = async () => {
    await updatePricesWrapper(async () => {
      const newQuantity = quantity + 1
      // eslint-disable-next-line no-unused-vars
      const updatedCart = await updateLineItem(id, newQuantity)
      // checkForAvailableDiscount(updatedCart)
    })
  }

  if (!shown) return null

  const frame2PlusCopy =
    'Send personalized doodles, adjust settings from the app, and more on your Frame 2 with Plus Plan. Your first month is free! After that, Plus will be billed $39 annually. Cancel anytime.'

  const calendarPlusCopy =
    "Add photos and use Magic Import on any of your Skylight Calendars with the Plus Plan. Your first month of Plus is free! After that, you'll be billed $79 annually. Cancel anytime."

  const returnGuaranteeCopy = t(
    'Skylight exists to make your life easier. That’s why we provide friendly support every day of the year and a 120-day free return guarantee. If you’re not loving your purchase, we’ll help make it right.'
  )

  const productDisclosure = {
    'The Skylight Calendar with Plus Plan': calendarPlusCopy,
    'The Skylight Calendar 15" with Plus Plan': calendarPlusCopy,
    'Calendar Plus':
      'To activate your subscription, check out using the same email your Skylight Calendar is tied to. You can find your tied email in the profile tab of the Skylight app.',
    'Skylight Frame 2 (Classic/Black) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Classic/White) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Classic/Lagoon) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Classic/Sage) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Wood/Walnut) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Wood/Birch) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Shadow Box/Charcoal) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Shadow Box/Aluminum) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Cover/Black) with Plus': frame2PlusCopy,
    'Skylight Frame 2 (Cover/White) with Plus': frame2PlusCopy,
    'Skylight Calendar Max (Classic/White) with Plus Plan': calendarPlusCopy,
    'Skylight Calendar Max (Classic/Black) with Plus Plan': calendarPlusCopy,
    'Skylight Calendar Max (Shadow Box/Natural Aluminum) with Plus Plan': calendarPlusCopy,
    'Skylight Calendar Max (Shadow Box/Charcoal) with Plus Plan': calendarPlusCopy,
  }

  const PRODUCT_LINK_OVERRIDE = {
    'The Skylight Calendar with Plus Plan': CAL_BUY_LINK,
    'The Skylight Calendar 15" with Plus Plan': CAL_BUY_LINK,
    'Skylight Calendar Max': CAL_MAX_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Natural Aluminum)': CAL_MAX_ALUMINUM_BUY_LINK,
    'Backordered: Skylight Calendar Max (Shadow Box/Natural Aluminum)': CAL_MAX_ALUMINUM_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Charcoal)': CAL_MAX_CHARCOAL_BUY_LINK,
    'Backordered: Skylight Calendar Max (Shadow Box/Charcoal)': CAL_MAX_CHARCOAL_BUY_LINK,
    'Skylight Calendar Max (Classic/White)': CAL_MAX_WHITE_BUY_LINK,
    'Backordered: Skylight Calendar Max (Classic/White)': CAL_MAX_WHITE_BUY_LINK,
    'Skylight Calendar Max (Classic/Black)': CAL_MAX_BLACK_BUY_LINK,
    'Backordered: Skylight Calendar Max (Classic/Black)': CAL_MAX_BLACK_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Natural Aluminum) with Plus Plan': CAL_MAX_ALUMINUM_BUY_LINK,
    'Backordered: Skylight Calendar Max (Shadow Box/Natural Aluminum) with Plus Plan':
      CAL_MAX_ALUMINUM_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Charcoal) with Plus Plan': CAL_MAX_CHARCOAL_BUY_LINK,
    'Backordered: Skylight Calendar Max (Shadow Box/Charcoal) with Plus Plan':
      CAL_MAX_CHARCOAL_BUY_LINK,
    'Skylight Calendar Max (Classic/White) with Plus Plan': CAL_MAX_WHITE_BUY_LINK,
    'Backordered: Skylight Calendar Max (Classic/White) with Plus Plan': CAL_MAX_WHITE_BUY_LINK,
    'Skylight Calendar Max (Classic/Black) with Plus Plan': CAL_MAX_BLACK_BUY_LINK,
    'Backordered: Skylight Calendar Max (Classic/Black) with Plus Plan': CAL_MAX_BLACK_BUY_LINK,
    'Skylight Frame 2 (Classic/Black) with Plus': FRAME_2_BLACK_BUY_LINK,
    'Skylight Frame 2 (Classic/White) with Plus': FRAME_2_WHITE_BUY_LINK,
    'Skylight Frame 2 (Classic/Lagoon) with Plus': FRAME_2_LAGOON_BUY_LINK,
    'Skylight Frame 2 (Classic/Sage) with Plus': FRAME_2_SAGE_BUY_LINK,
    'Skylight Frame 2 (Wood/Walnut) with Plus': FRAME_2_WALNUT_BUY_LINK,
    'Skylight Frame 2 (Wood/Birch) with Plus': FRAME_2_BIRCH_BUY_LINK,
    'Skylight Frame 2 (Shadow Box/Charcoal) with Plus': FRAME_2_CHARCOAL_BUY_LINK,
    'Skylight Frame 2 (Shadow Box/Aluminum) with Plus': FRAME_2_ALUMINUM_BUY_LINK,
    'Skylight Frame 2 (Cover/Black) with Plus': FRAME_2_COVER_BLACK_BUY_LINK,
    'Skylight Frame 2 (Cover/White) with Plus': FRAME_2_COVER_WHITE_BUY_LINK,
  }

  const productLink = PRODUCT_LINK_OVERRIDE[title] || `/products/${handle}`
  const regex = /^Skylight Frame 2(?: \([^)]+\))? with Plus$/

  const isFrameWithPlus = regex.test(title)

  const disclosure =
    title === 'Snap Frame'
      ? variantTitle
      : isFreeProduct
      ? returnGuaranteeCopy
      : productDisclosure[title]

  const ProductImageContainer = isFreeProduct ? React.Fragment : Link
  const TitleContainer = isFreeProduct ? React.Fragment : TitleLink

  return (
    <TableRow>
      <ProductTD textAlign="left">
        <HiddenOnMobile>
          <ProductImageContainer to={productLink}>
            <img src={url} width={100} alt={title} />
          </ProductImageContainer>
        </HiddenOnMobile>
        <ProductInfo>
          <TitleContainer to={productLink}>{title}</TitleContainer>
          <FrameWithPlusExplainer>
            {IS_FOREIGN_COUNTRY && title === 'The Skylight Frame' ? null : (
              <Trans>{disclosure}</Trans>
            )}
          </FrameWithPlusExplainer>
          {!isFreeProduct && (
            <Remove onClick={() => handleRemoveLineItem(id)}>
              <Trans>Remove</Trans>
            </Remove>
          )}
        </ProductInfo>
      </ProductTD>
      <DesktopOnlyTD>
        <TD>
          <p>{amount === '0.0' ? t('Free').toUpperCase() : FormattedCurrency(amount)}</p>
        </TD>
      </DesktopOnlyTD>
      <TD>
        <LeftQuantityButton
          type="button"
          onClick={decrementLineItem}
          disabled={updatingPrices}
          hide={isFreeProduct || isFrameWithPlus}
        >
          -
        </LeftQuantityButton>
        {'  '}
        {quantity}
        {'  '}
        <RightQuantityButton
          type="button"
          onClick={incrementLineItem}
          disabled={updatingPrices || subtotalQuantity > 24}
          hide={isFreeProduct || isFrameWithPlus}
        >
          +
        </RightQuantityButton>
      </TD>
      <HiddenOnDesktop>
        <TD center>
          <Price red={strikethrough}>
            {amount === '0.0' ? t('Free').toUpperCase() : FormattedCurrency(amount)}
          </Price>
        </TD>
      </HiddenOnDesktop>
      <DesktopOnlyTD>
        <LineItemTotalCtn>
          {updatingPrices ? (
            <LoadingEllipsis />
          ) : (
            <SubTotal strikethrough={strikethrough}>
              {amount === '0.0' ? t('Free').toUpperCase() : FormattedCurrency(quantity * amount)}
            </SubTotal>
          )}
        </LineItemTotalCtn>
      </DesktopOnlyTD>
    </TableRow>
  )
}

export default LineItem

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  setUpdatingPrices: PropTypes.func.isRequired,
  updatingPrices: PropTypes.bool.isRequired,
  strikethrough: PropTypes.bool.isRequired,
  subtotalQuantity: PropTypes.number.isRequired,
  setHasFrame2: PropTypes.func.isRequired,
  setHasSnapFrame: PropTypes.func.isRequired,
  isFreeProduct: PropTypes.bool,
}
